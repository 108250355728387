import {
  createValidator,
  isEmail,
  isPhone,
  ValidationChecks,
  ValidationRule,
} from '../lib/validation';
import { exists } from '../lib/utils';

type RecipientType = 'me' | 'other';
type ContactMethod = 'phone' | 'whatsapp' | 'telegram';
type Delivery = 'pickup' | 'courier' | 'unknown';
export type CourierType = 'shop_courier' | 'costume_courier' | 'service_courier' | 'tree' | 'tree_pot';
type Payment = 'card' | 'card_upon_receipt' | 'cash' | 'other';
type Replacements = 'call' | 'dont_call';
type PostcardType = 'no' | 'standard' | 'custom';

export type AdditionalServiceCode = 'tree_installation' | 'tree_disassembly';
type AdditionalServices = AdditionalServiceCode[];

type CheckoutForm = {
  buyerName: string;
  buyerPhone: string;
  buyerEmail: string;
  buyerContactMethod: ContactMethod;
  recipientName: string;
  recipientPhone: string;
  recipientContactMethod: ContactMethod;
  recipientType: RecipientType;
  dontTellRecipientBuyersName: boolean;
  delivery: Delivery;
  pickupDate: string;
  pickupTime: string;
  courierType: CourierType;
  courierAddress: string;
  courierAddressLat: string;
  courierAddressLon: string;
  courierSuite: string;
  courierIntercom: string;
  courierEntrance: string;
  courierFloor: string;
  courierDate: string;
  courierInterval: string;
  courierPreferredTime: string;
  isCourierExactTime: boolean;
  replacements: Replacements;
  payment: Payment;
  postcardType: PostcardType;
  addPostcard: boolean;
  postcard: string; // text
  postcardURL: string;
  addComment: boolean;
  comment: string;
  additionalServices: AdditionalServices;
};

type CheckoutFormChecks = ValidationChecks<CheckoutForm>;
type Rule = ValidationRule<CheckoutForm>;

const isAnotherPerson = (type: string) => type === 'other';
const isEmailEmpty = (email: string) => !exists(email);

const isNotPickup = (delivery: Delivery) => delivery !== 'pickup';
const isNotCourier = (delivery: Delivery) => delivery !== 'courier' && delivery !== 'unknown';
const isNotAddressDelivery = (delivery: Delivery) => delivery !== 'courier';

const isNotCard = (payment: Payment) => payment !== 'card';

const isNotNotExactTime = (courierInterval: string) => courierInterval !== 'exact';

const validateBuyerNamePresence: Rule = ({ buyerName }) => exists(buyerName);
const validateBuyerPhonePresence: Rule = ({ buyerPhone }) => exists(buyerPhone);
const validateBuyerPhoneCorrectness: Rule = ({ buyerPhone }) => isPhone(buyerPhone);

const validateBuyerEmailPresence: Rule = ({ payment, buyerEmail }) =>
  isNotCard(payment) || exists(buyerEmail);
const validateBuyerEmailCorrectness: Rule = ({ payment, buyerEmail }) =>
  isNotCard(payment) || isEmail(buyerEmail);

const validateRecipientNamePresence: Rule = ({ recipientType, recipientName }) =>
  !isAnotherPerson(recipientType) || exists(recipientName);
const validateRecipientPhonePresence: Rule = ({ recipientType, recipientPhone }) =>
  !isAnotherPerson(recipientType) || exists(recipientPhone);
const validateRecipientPhoneCorrectness: Rule = ({ recipientType, recipientPhone }) =>
  !isAnotherPerson(recipientType) || isPhone(recipientPhone);

const validatePickupDate: Rule = ({ delivery, pickupDate }) =>
  isNotPickup(delivery) || exists(pickupDate);
const validatePickupTime: Rule = ({ delivery, pickupTime }) =>
  isNotPickup(delivery) || exists(pickupTime);

const validateCourierAddress: Rule = ({ delivery, courierAddress }) =>
  isNotAddressDelivery(delivery) || exists(courierAddress);

const validateCourierDate: Rule = ({ delivery, courierDate }) =>
  isNotCourier(delivery) || exists(courierDate);
const validateCourierInterval: Rule = ({ delivery, courierInterval }) =>
  isNotCourier(delivery) || exists(courierInterval);
const validateCourierTime: Rule = ({ delivery, courierInterval, courierPreferredTime }) =>
  isNotCourier(delivery) || isNotNotExactTime(courierInterval) || exists(courierPreferredTime);

const validatePostcard: Rule = ({ addPostcard, postcard }) => !addPostcard || exists(postcard);
const validateComment: Rule = ({ addComment, comment }) => !addComment || exists(comment);

const checkoutFormChecks: CheckoutFormChecks = {
  buyerName: [
    {
      validate: validateBuyerNamePresence,
      error: 'Заполните имя, чтобы мы знали как к Вам обращаться',
    },
  ],
  buyerPhone: [
    {
      validate: validateBuyerPhonePresence,
      error: 'Заполните телефон чтобы связаться для подтверждения заказа',
    },
    {
      validate: validateBuyerPhoneCorrectness,
      error: 'Введите телефон в формате +7 000 000-00-00',
    },
  ],
  buyerEmail: [
    {
      validate: validateBuyerEmailPresence,
      error: 'Введите вашу электронную почту, чтобы мы могли выслать чек',
    },
    {
      validate: validateBuyerEmailCorrectness,
      error: 'Неверный формат адреса электронной почты',
    },
  ],
  recipientName: [
    {
      validate: validateRecipientNamePresence,
      error: 'Заполните имя получателя',
    },
  ],
  recipientPhone: [
    {
      validate: validateRecipientPhonePresence,
      error: 'Заполните телефон получателя заказа',
    },
    {
      validate: validateRecipientPhoneCorrectness,
      error: 'Введите телефон в формате +7 000 000-00-00',
    },
  ],
  pickupDate: [
    {
      validate: validatePickupDate,
      error: 'Укажите дату самовывоза',
    },
  ],
  pickupTime: [
    {
      validate: validatePickupTime,
      error: 'Укажите время самовывоза',
    },
  ],
  courierDate: [
    {
      validate: validateCourierDate,
      error: 'Укажите дату доставки',
    },
  ],
  courierInterval: [
    {
      validate: validateCourierInterval,
      error: 'Укажите интервал доставки',
    },
  ],
  courierPreferredTime: [
    {
      validate: validateCourierTime,
      error: 'Укажите время доставки',
    },
  ],
  courierAddress: [
    {
      validate: validateCourierAddress,
      error: 'Укажите адрес доставки',
    },
  ],
  postcard: [
    {
      validate: validatePostcard,
      error: 'Не забудьте текст для открытки',
    },
  ],
  comment: [
    {
      validate: validateComment,
      error: 'Вы хотели добавить комментарий?',
    },
  ],
};

// FIXME: reusing validateBuyerNamePresence here breaks adding products to the cart on prod builds
const validateBuyerNamePresence1: Rule = ({ buyerName }) => exists(buyerName);
const validateBuyerPhonePresence1: Rule = ({ buyerPhone }) => exists(buyerPhone);
const validateBuyerPhoneCorrectness1: Rule = ({ buyerPhone }) => isPhone(buyerPhone);
const quickOrderFormChecks: CheckoutFormChecks = {
  buyerName: [
    {
      validate: validateBuyerNamePresence1,
      error: 'Заполните имя, чтобы мы знали как к Вам обращаться',
    },
  ],
  buyerPhone: [
    {
      validate: validateBuyerPhonePresence1,
      error: 'Заполните телефон чтобы связаться для подтверждения заказа',
    },
    {
      validate: validateBuyerPhoneCorrectness1,
      error: 'Введите телефон в формате +7 000 000-00-00',
    },
  ],
};

const validateForm = createValidator(checkoutFormChecks);
const validateQuickOrderForm = createValidator(quickOrderFormChecks);

export function checkoutStore(Alpine) {
  const defaults: Partial<CheckoutForm> = {
    recipientName: '',
    recipientPhone: '',
    recipientContactMethod: 'phone',
    dontTellRecipientBuyersName: true,
    recipientType: 'me',
    delivery: 'pickup',
    pickupDate: '',
    pickupTime: '',
    courierType: 'shop_courier',
    courierDate: '',
    courierInterval: '',
    courierPreferredTime: '',
    isCourierExactTime: false,
    replacements: 'call',
    payment: 'card',
    postcardType: 'no',
    addPostcard: false,
    postcard: '',
    postcardURL: '',
    addComment: false,
    comment: '',
    additionalServices: [],
  };

  const form: CheckoutForm = {
    buyerName: Alpine.$persist('').as('checkoutBuyerName'),
    buyerPhone: Alpine.$persist('').as('checkoutBuyerPhone'),
    buyerEmail: Alpine.$persist('').as('checkoutBuyerEmail'),
    buyerContactMethod: Alpine.$persist('phone').as('checkoutBuyerContactMethod'),
    recipientName: Alpine.$persist('').as('recipientBuyerName'),
    recipientPhone: Alpine.$persist('').as('recipientPhoneName'),
    recipientContactMethod: Alpine.$persist('phone').as('recipientBuyerContactMethod'),
    dontTellRecipientBuyersName: Alpine.$persist(true).as('checkoutDontTellRecipientBuyersName'),
    recipientType: Alpine.$persist('me').as('checkoutRecipientType'),
    delivery: Alpine.$persist('pickup').as('checkoutDelivery'),
    pickupDate: Alpine.$persist('').as('checkoutPickupDate'),
    pickupTime: Alpine.$persist('').as('checkoutPickupTime'),
    courierType: Alpine.$persist('shop_courier').as('checkoutCourierType'),
    courierAddress: Alpine.$persist('').as('checkoutCourierAddress'),
    courierAddressLat: Alpine.$persist(0).as('checkoutCourierAddressLat'),
    courierAddressLon: Alpine.$persist(0).as('checkoutCourierAddressLon'),
    courierSuite: Alpine.$persist('').as('checkoutCourierSuite'),
    courierIntercom: Alpine.$persist('').as('checkoutCourierIntercom'),
    courierEntrance: Alpine.$persist('').as('checkoutCourierEntrance'),
    courierFloor: Alpine.$persist('').as('checkoutCourierFloor'),
    courierDate: Alpine.$persist('').as('checkoutCourierDate'),
    courierInterval: Alpine.$persist('').as('checkoutCourierInterval'),
    courierPreferredTime: Alpine.$persist('').as('checkoutCourierPreferredTime'),
    isCourierExactTime: Alpine.$persist(false).as('checkoutCourierIsExactTime'),
    replacements: Alpine.$persist('dont_call').as('checkoutReplacements'),
    payment: Alpine.$persist('card').as('checkoutPayment'),
    postcardType: Alpine.$persist('no').as('checkoutPostcardType'),
    addPostcard: Alpine.$persist(false).as('checkoutAddPostcard'),
    postcard: Alpine.$persist('').as('checkoutPostcard'),
    postcardURL: Alpine.$persist('').as('checkoutPostcardURL'),
    addComment: Alpine.$persist(false).as('checkoutAddComment'),
    comment: Alpine.$persist('').as('checkoutComment'),
    additionalServices: Alpine.$persist([]).as('additionalServices'),
  };

  Alpine.store('checkout', {
    form,
    pickupIntervals: [],
    courierIntervals: [],
    courierPreferredTimeIntervals: [],
    init() {},
    reset() {
      this.form = { ...this.form, ...defaults };
    },
    validate() {
      return validateForm(this.form);
    },
    validateQuickOrder() {
      return validateQuickOrderForm(this.form);
    },
  });
}
