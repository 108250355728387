import Swiper, { FreeMode, Navigation, SwiperOptions } from 'swiper';

export function tabs(Alpine) {
  Alpine.data('tabs', tabsComponent);

  function tabsComponent({ initialTab }) {
    return {
      tab: initialTab,
      swiper: null,
      init() {
        this.$nextTick(() => {
          this.initSwiper();
        });
      },
      initSwiper() {
        let params: SwiperOptions = {
          modules: [FreeMode],
          direction: 'horizontal',
          freeMode: true,
          slidesPerView: 'auto',
        };
        if (this.$refs.prev && this.$refs.next) {
          params.modules.push(Navigation);
          params.navigation = {
            nextEl: this.$refs.next,
            prevEl: this.$refs.prev,
          };
        }

        this.swiper = new Swiper(this.$refs.swiper, params);
      },
      setTab(tab) {
        this.tab = tab;
      },
      destroy() {
        if (this.swiper) this.swiper.destroy();
      },
    };
  }
}
