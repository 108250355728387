type CountdownParams = {
  now: string;
  till: string;
};

function addZero(num) {
  return ('0' + num).slice(-2);
}

export function countdown(Alpine) {
  Alpine.data('countdown', countdownComponent);

  function countdownComponent({ now, till }: CountdownParams) {
    return {
      day: 0,
      hour: 0,
      min: 0,
      sec: 0,
      isEnded: false,
      now: new Date(now).getTime(),
      till: new Date(till).getTime(),
      init() {
        this.updateTime();
      },
      updateTime() {
        const delta = this.till - this.now;
        if (delta) {
          this.day = Math.floor(delta / (24 * 60 * 60 * 1000));
          this.hour = Math.floor((delta % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

          this.min = Math.floor((delta % (24 * 60 * 60 * 1000)) / (60 * 1000)) % 60;
          this.min = addZero(this.min);

          this.sec = Math.floor(((delta) % (24 * 60 * 60 * 1000)) / 1000) % 60 % 60;
          this.sec = addZero(this.sec);

          this.now += 1000;
          setTimeout(this.updateTime.bind(this), 1000);
        } else {
          this.isEnded = true;
        }
      },
    };
  }
}
